import {getMainNavigatorLanguage} from "./lang";

const navigatorLanguage = getMainNavigatorLanguage();
const defaultD2Version ="d2r";


export function decodePath(path) {
    const [,lang,d2ver,tab,tabDetail] = path.split('/');
    let tabIndex = 0;
    let pickedTab = "";
    switch(tab) {
        default:
        case 'home': tabIndex = 0; pickedTab = 'home'; break;
        case 'runes': tabIndex = 1; pickedTab = 'runes'; break;
        case 'runewords': tabIndex = 2; pickedTab = 'runewords'; break;
        case 'chest': tabIndex = 3; pickedTab = 'chest'; break;
    }

    let langStr = "";
    switch(lang) {
        case "de":
        case "en":
            langStr = lang;
            break;
        default:
    }

    let d2verStr = "";
    switch(d2ver) {
        case "d2r":
            d2verStr = d2ver;
            break;
        default:
    }

    return {
        path,
        tabIndex,
        tabName: pickedTab,
        lang: langStr || navigatorLanguage,
        d2ver: d2verStr || defaultD2Version,
        tabDetail
    }
}



