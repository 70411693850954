<template>
    <v-ons-fab
        ripple
        position="bottom right"
        :style="fabStyle"
        @click="$emit('click', $event)"
        >
        <v-ons-icon icon="fa-cog"></v-ons-icon>
    </v-ons-fab>
</template>

<script>
import {isOnDekstop} from "../util/browser";


export default {
    name: "OptionsButton",
    methods: {
        options() {
        }
    },

    computed: {
        fabStyle() {
            return {
                right: "10px",
                color: "#aaa",
                backgroundColor: "#333",
                bottom: isOnDekstop(this.$ons) ? "10px" : "55px",
            }
        }
    }
}
</script>

<style scoped>

</style>