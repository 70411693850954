import kb_d2rv1 from '../kb/d2r_v1.json5';
import kb_d2rv1_rwords from '../kb/d2r_v1_rwords.json5';



const availableKBs = {
    "d2r": {
        kb: {...kb_d2rv1, "cube": kb_d2rv1_rwords },
        name: "D2R release",
    }
};

export function kbFromVer(ver) {
    return availableKBs[ver] || availableKBs[Object.keys(availableKBs)[0]];
}

export function runeList(kb) {
    return [...kb.runes];
}

export function runeOrderValue(kb, runeName) {
    for (let i=0; i<kb.runes.length; i++) {
        const { name } = kb.runes[i];
        if (name == runeName ||
            name == "rune:" + runeName) {
            return (i + 1) * (i + 1);
        }
    }
    throw new Error("Unknown item: " + runeName);
}

export function runeWordList(kb) {
    return [...kb.cube.filter(e => e.type == "RUNEWORD")];
}

/** true if the given word is a rune */
export function isRune(kb, searchWord) {
    const lowerWord = searchWord.toLowerCase();
    return kb.runes.find(r => r.name.toLowerCase() == lowerWord) ? true : false;
}


export function isForHand(kb, itemList) {
    return itemList
        .map(item => item.toLowerCase())
        .find(item =>
           item == "melee_weapons"
        || item == "sword"
        || item == "club"
        || item == "mace"
        || item == "axe"
        || item == "wand"
        || item == "staves"
        || item == "stave"
        || item == "scepter"
        || item == "missle_weapons"
        || item == "weapons"
        || item == "swords"
        || item == "clubs"
        || item == "staves"
        || item == "scepters"
        || item == "crossbows"
        || item == "wands"
        || item == "axes"
        || item == "katars"
        || item == "weapon"
        || item == "polearms"
    )
}

export function isForShieldHand(kb, itemList) {
    return itemList
        .map(item => item.toLowerCase())
        .find(item =>
            item == "shield"
            || item == "shields"
            || item == "paladin_shields"
    )
}

export function isForHead(kb, itemList) {
    return itemList
        .map(item => item.toLowerCase())
        .find(item =>
        item == "helm"
        || item == "helms"
    )
}

export function isForBody(kb, itemList) {
    return itemList
        .map(item => item.toLowerCase())
        .find(item =>
           item == "body"
        || item == "armor"
        || item == "body_armor"
    )
}


