import 'onsenui/css/onsenui.css';
//import 'onsenui/css/onsen-css-components.css';
import './colors/onsen-css-components.css';
import './colors/desktop.css';
import './colors/response.css';
import 'animate.css';

import Vue from 'vue'
import AppNavigator from './ui/AppNavigator.vue'
import VueOnsen from 'vue-onsenui';
import store from './store/store.js';
import Vuex from 'vuex';
import i18n from './i18n'
import version from './version.json'
import VueRouter from 'vue-router'
import {decodePath} from "./util/navigation";

console.log(version);

Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(VueOnsen);
Vue.use(VueRouter);

const routes = [
    { path: '/:lang/:d2ver/home', component: {template: '<div>main</div>'} },

    { path: '/:lang/:d2ver/runes', component: {template: '<div>runes</div>'}, meta: {titleTransKey: "window.title.runes"} },
    { path: '/:lang/:d2ver/runes/:rune', component: {template: '<div>single rune</div>'}, meta: {titleTransKey: "window.title.runes"} },

    { path: '/:lang/:d2ver/runewords', component: {template: '<div>runword list</div>'}, meta: {titleTransKey: "window.title.runewords"} },
    { path: '/:lang/:d2ver/runewords/:runeword', component: {template: '<div>single runeword</div>'}, meta: {titleTransKey: "window.title.runewords"} },

    { path: '/:lang/:d2ver/chest', component: {template: '<div>chest</div>'}, meta: {titleTransKey: "window.title.chest"} },
    { path: '/:lang/:d2ver/chest/:chestconfig', component: {template: '<div>link-my-chest</div>'}, meta: {titleTransKey: "window.title.chest"} },
]

const router = new VueRouter({
    routes,
    mode: "history"
})


const setupUI = () => {
    new Vue({
        render: h => h(AppNavigator),
        store: new Vuex.Store(store),
        i18n,
        router,

        beforeCreate() {
            this.$ons.platform.select('ios')
        }
    }).$mount('#app');
};

if (window.cordova) {
    const onDeviceReady = () => {
        setupUI();
    };
    document.addEventListener("deviceready", onDeviceReady, false);
}
else {
    setupUI();
}