<template>
    <v-ons-page>


        <div class="content wall">

            <h2>{{$t("welcome.text.head1")}}</h2>

            <!--v-ons-button @click="setLang('de')">DE</v-ons-button>
            <v-ons-button @click="setLang('en')">EN</v-ons-button-->

            <section>
                Hier kannst du alles zum Diablo 2 Ressurected
                und Runen erfahren.
                <br>
                {{test1}}
            </section>

            <section style="color: #000; background-color: #fffb00; border-radius: 3px; font-size: 0.8rem; padding: 0px 3px;">
                ALPHA!! Bitte erstmal nich weiter geben :)
            </section>

            <section style="color: #000; background-color: #ff0099; border-radius: 3px; font-size: 0.8rem; padding: 0px 3px;">
                Jetzt mit Übersetzung! :-)
            </section>

            <!--ul>
                <li>Welche Runen gibt es?</li>
                <li>Wie mache ich aus einer Nef eine Zod?</li>
                <li>Welche Rezepte kann ich mit meiner Kiste machen?</li>
            </ul>
            <section>
                Runehilde funktioniert ideal auf dem Smartphone oder zweiten Desktop um beim
                Baalrun eben schnell zu gucken: "was mach ich denn nun mit einer Lum Rune?"
            </section>
            <section>
                Deine Kiste und usw. werden in deinem Browser gespeichert und bleiben erhalten.
                Okay, außer auf iOS/iPad Geräten da löscht das Betriebssystem diese gelegentlich
                dort bitte nicht traurig sein.
            </section>
            <section>
                Runehilde gibt sich Mühe euch zu helfen ist aber nicht mehr die jüngste.
                Bitte bei allen Rezepten und Angaben immer dran denken: <b>alles auf eigene Gefahr und
                ohne Gewähr</b>.
                Teile oder sogar alles kann falsch sein. Wenn du etwas entdeckst was falsch ist,
                bitte gerne eben Kontakt aufnehmen.
            </section>
            <section>
                Wenn Dir Runehilde weiter helfen konnte sags gern weiter. Sie freut sich
                über Besuch. Auch sind Vorschläge zur Verbesserung und Hinweise auf Fehler immer
                gern gesehen.
            </section>

            <h3 style="margin-top: 30px">Runen und Mehr</h3>
            <section>
                Unten im Hauptmenü unter <b>Runen und Mehr</b> teilt Runehilde ihre
                persönliche Runensammlung mit euch und zeigt alle Runen die sie im
                Spiel gefunden hat. Nicht mehr sicher was eine Rune kann oder welchen Edelstein
                man braucht um sie zu erzeugen? Hier gibts die Antwort.
            </section>

            <h3 style="margin-top: 30px">Rezepte</h3>
            <section>
                In der <b>Rezepte</b> Abteilung hat Runehilde alle Runenrezepte zusammen
                getragen die sie finden konnte.
            </section>

            <h3 style="margin-top: 30px">Kiste</h3>
            <section>
                Hier könnt ihr mit wenigen klicks eure persönliche Kiste zusammen schieben
                so wie sie gerade in eurem Spiel ist. Die Arbeit lohnt sich, denn
                Runehilde verrät euch dann welche Rezepte ihr mit eurem aktueller
                Runenauswahl bereits erreichen könnt. Probiert es aus, ist genial!
            </section-->

            <below-list></below-list>
        </div>

    </v-ons-page>
</template>

<script>

    import i18n from './../i18n';
    import OptionsButton from "./OptionsButton";
    import BelowList from "./BelowList";
    import {decodePath} from "../util/navigation";



    export default {

        components: {BelowList, OptionsButton  },

        beforeCreate() {



        },

        computed: {

            test1() {
                return this.$route.params.xxx;
            }

        },

        methods: {

        },
        data () {
            return {

            }
        },

    };
</script>

<style scoped>

    .muh {

    }

    .wall {
        padding: 10px;
    }

    section {
        margin-top: 10px;
    }


</style>