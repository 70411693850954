export function getNavigatorLanguage() {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}


export function getMainNavigatorLanguage() {
    const complex = getNavigatorLanguage();
    if (!complex)
        return "";
    if (complex.indexOf("-") === -1)
        return complex;
    const [mainLang] = complex.split('-')
    return mainLang;
}