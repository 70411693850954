<template id="main-page">
    <v-ons-navigator
        swipeable
        swipe-target-width="50px"
        :page-stack="pageStack"
        :pop-page="storePop"
        _options="options"
        @postpush="showPopTip"
        :class="{ 'border-radius': borderRadius }"
    ></v-ons-navigator>
</template>
<script>
    import MainTabs from './MainTabs.vue';
    import i18n from '../i18n';

    export default {
        beforeCreate() {
            this.$store.commit('navigator/push', MainTabs);

        },
        data() {
            return {
                shutUp: this.md
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    const xx = i18n.t(to.meta.titleTransKey || 'window.title');
                    document.title = "" + xx
                }
            },
        },
        computed: {
            pageStack() {
                return this.$store.state.navigator.stack;
            },
            // options() {
            //     return this.$store.state.navigator.options;
            // },
            borderRadius() {
                return new URL(window.location).searchParams.get('borderradius') !== null;
            },
        },
        methods: {
            storePop() {
                this.$store.commit('navigator/pop');
            },
            showPopTip() {
            },
        }
    };
</script>
<style>

</style>