<template>
    <div class="main">
        <div class="head">
            <h1>{{name}}</h1>
            <div><v-ons-button @click="close()"><v-ons-icon icon="fa-close"></v-ons-icon></v-ons-button></div>
        </div>
        <div v-if="craftInfo && craftInfo.length > 0" class="cubeText">
            <v-ons-icon icon="fa-cube"></v-ons-icon>&nbsp;
            <span v-for="(nm, index) in craftInfo" :key="index">
                <span v-if="index > 0"> + </span>
                <span :class="{'missing-rune':nm.missing}">{{nm.rune}}</span>
            </span>
        </div>
        <div v-else class="cubeText">
            <v-ons-icon icon="fa-cube"></v-ons-icon> {{cubeText}}
        </div>

        <div>
            <div>lvl</div>
            <div>
                {{reqLvl}}
            </div>
        </div>

        <div>
            <div>
                <div>
                    <v-ons-icon icon="fa-legal" v-if="isWeapon(item)"></v-ons-icon>
                    <v-ons-icon icon="fa-male" v-else-if="isArmor(item)"></v-ons-icon>
                    <v-ons-icon icon="fa-user" v-else-if="isHelm(item)"></v-ons-icon>
                    <v-ons-icon icon="fa-shield" v-else-if="isShield(item)"></v-ons-icon>
                    <v-ons-icon icon="fa-question" v-else></v-ons-icon>
                </div>
                <div>
                    <div v-for="(t, ti) in itemTexts" :key="name + ti">
                        <span>{{t}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div v-for="(e, ei) in effectsText" :key="name + ei"><span>{{e}}</span></div>
            </div>
            <div>
                <!--v-ons-icon icon="fa-star"></v-ons-icon-->
            </div>
        </div>
    </div>
</template>

<script>

import {
    runeWordList,
    isRune,
    isForBody,
    isForHand,
    isForShieldHand,
    isForHead, kbFromVer, runeList, runeOrderValue,
} from '../util/itemknowledge'
import {decodePath} from "../util/navigation";
import i18n from "../i18n";
import {toCmpString} from "../util/strings";

export default {
    name: "SingleRuneWord",

    methods: {

        close() {
            // const pathParts = decodePath(this.$route.path);
            // const path = `/${pathParts.lang}/${pathParts.d2ver}/runewords`;
            // this.$router.push(path);

            this.$router.back();

        },

        isWeapon(itemList) {
            return isForHand(this.kb, itemList);
        },
        isArmor(itemList) {
            return isForBody(this.kb, itemList);
        },
        isHelm(itemList) {
            return isForHead(this.kb, itemList);
        },
        isShield(itemList) {
            return isForShieldHand(this.kb, itemList);
        },
    },

    computed: {

        runewordInfo() {
            const searchCmp = this.runeword; // toCmpString(this.runeword);
            let candiates = [];
            let similar = [];
            const runewords = this.$store.state.items.runeWordList;
            runewords.forEach(r => {
                if (r.linkName == searchCmp) {
                    candiates.push(r);
                }
            });
            return candiates.length > 0 ? candiates[0] : {}
        },

        cubeText() {
            return "";
        },

        item() {
            return this.runewordInfo.item || [];
        },
        reqLvl() {
            return this.runewordInfo.reqLvl || "";
        },
        name() {
            return this.runewordInfo.name || "";
        },
        craftInfo() {
            return this.runewordInfo.craftInfo || [];
        },
        itemTexts() {
            return this.runewordInfo.itemTexts || [];
        },
        effectsText() {
            return this.runewordInfo.effectsText || [];
        }
    },

    props: [
        "runeword"
    ]
}
</script>

<style scoped>

.main {
    background: #000;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 1000;
    padding: 10px 20px;
}

.head {
    display: flex;
}

.head > *:first-child {
    padding: 0;
    margin: 0;
    flex: 1;
    padding-left: 30px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.head > div:nth-child(2) {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.head > div:nth-child(2) > .button {
    background-color: #999;
    color: #b12525;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.head > div:nth-child(2) > .button > * {
    margin: 0;
    padding: 0;
}

</style>