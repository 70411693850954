<template>
    <v-ons-page>
        <div class="content">
            <!--div class="runeBox">
                <div v-for="r in availableRunes" :key="r.id" class="runeX" @click.prevent="openLink(r.link)">
                    <div>{{r.name}}</div>
                </div>
            </div-->
            <div class="sticky-toolbar" style="display: flex; align-items: center;">
                <v-ons-icon icon="fa-search"></v-ons-icon>
                <v-ons-input v-model="nameFilter" type="search" :placeholder="$t('message.filter')" style="margin-left: 10px; flex: 1"></v-ons-input>
                <div class="filter-icons" v-show="false">
                    <div
                        @click="setItemFilter('weapon')"
                        :class="{'active-item-filter': itemTypeFilter == 'weapon'}"
                        >
                        <v-ons-icon icon="fa-legal"></v-ons-icon>
                    </div>
                    <div
                        @click="setItemFilter('armor')"
                        :class="{'active-item-filter': itemTypeFilter == 'armor'}"
                        >
                        <v-ons-icon icon="fa-male"></v-ons-icon>
                    </div>
                    <div
                        @click="setItemFilter('helm')"
                        :class="{'active-item-filter': itemTypeFilter == 'helm'}"
                        >
                        <v-ons-icon icon="fa-user"></v-ons-icon>
                    </div>
                    <div
                        @click="setItemFilter('shield')"
                        :class="{'active-item-filter': itemTypeFilter == 'shield'}"
                        >
                        <v-ons-icon icon="fa-shield"></v-ons-icon>
                    </div>
                </div>
            </div>

            <v-ons-list>
                <div v-for="(i, ii) in filteredList" :key="i.name" class="item global-textselect">
                    <div @click.prevent="openLink(i.link)">
                        <div>
                            <span class="">
                                <b><router-link :to="i.link">{{i.name}}</router-link></b><br>
                                <div class="cubeText" v-if="i.cubeText">
                                    <v-ons-icon icon="fa-cube"></v-ons-icon> {{i.cubeText}}
                                </div>
                            </span>
                        </div>
                        <div>
                            <div>lvl</div>
                            <div>
                                {{i.lvl}}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <!--v-ons-icon icon="fa-question"></v-ons-icon-->
                            </div>
                            <div></div>
                        </div>
                        <div>
                            <template v-if="itemTypeFilter">
                                <div class=" rune-details" v-for="(w, wi) in i.sockingEffectText[itemTypeFilter]" :key="i.name + '_weapon_' + w + '_' + wi + '_' + ii ">
                                    <span>
                                        <v-ons-icon icon="fa-legal"  v-show="wi == 0" v-if="itemTypeFilter == 'weapon'"></v-ons-icon>
                                        <v-ons-icon icon="fa-male"   v-show="wi == 0" v-else-if="itemTypeFilter == 'armor'"></v-ons-icon>
                                        <v-ons-icon icon="fa-user"   v-show="wi == 0" v-else-if="itemTypeFilter == 'helm'"></v-ons-icon>
                                        <v-ons-icon icon="fa-shield" v-show="wi == 0" v-else-if="itemTypeFilter == 'shield'"></v-ons-icon>
                                    </span>
                                    <span>{{w}}</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="rune-details" v-for="(w, wi) in i.sockingEffectText.weapon" :key="i.name + '_weapon_' + w + '_' + wi + '_' + ii ">
                                    <span><v-ons-icon icon="fa-legal" v-show="wi == 0"></v-ons-icon></span>
                                    <span>{{w}}</span>
                                </div>
                                <div class="rune-details" v-for="(w, wi) in i.sockingEffectText.armor" :key="i.name + '_armor_' + w + '_' + wi + '_' + ii ">
                                    <span><v-ons-icon icon="fa-male"  v-show="wi == 0"></v-ons-icon></span>
                                    <span>{{w}}</span>
                                </div>
                                <div class="rune-details" v-for="(w, wi) in i.sockingEffectText.helm" :key="i.name + '_helm_' + w + '_' + wi + '_' + ii ">
                                    <span><v-ons-icon icon="fa-user"  v-show="wi == 0"></v-ons-icon></span>
                                    <span>{{w}}</span>
                                </div>
                                <div class="rune-details" v-for="(w, wi) in i.sockingEffectText.shield" :key="i.name + '_shield_' + w + '_' + wi + '_' + ii ">
                                    <span><v-ons-icon icon="fa-shield"  v-show="wi == 0"></v-ons-icon></span>
                                    <span>{{w}}</span>
                                </div>
                            </template>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </v-ons-list>
            <below-list></below-list>
        </div>
        <single-rune
            v-if="pickedRune"
            :rune="pickedRune"
        ></single-rune>
    </v-ons-page>
</template>

<script>

import {kbFromVer, runeList} from '../util/itemknowledge'
    import i18n from '../i18n'
    import OptionsButton from "./OptionsButton";
    import BelowList from "./BelowList";
import {decodePath} from "../util/navigation";
import SingleRune from "./SingleRune";

    export default {

        components: {SingleRune, BelowList, OptionsButton  },

        beforeCreate() {



        },

        computed: {

            allRunes() {
                let i = 1;

                const out = this.$store.state.items.runeList.map(r => {
                    return {
                        id: i++,
                        ...r,
                    }
                });

                return out;

                // return runeList(this.kb).map(r => {
                //     return {
                //         id: i++,
                //         ...r,
                //     }
                // });
            },

            availableRunes() {
                const runes = this.allRunes;
                return [...runes];
            },

            pickedRune() {
                const pathParts = decodePath(this.$route.path);
                return pathParts.tabDetail;
            },

            filteredList() {
                const runeList = this.$store.state.items.runeList;

                const name = this.nameFilter.toLowerCase();
                return this.nameFilter
                    ? runeList.filter(r => r.searchWord.toLowerCase().indexOf(name) > -1)
                    : runeList;
            },
        },
        methods: {

            openLink(linkUrl) {
                if (!linkUrl)
                    return;
                this.$router.push(linkUrl);
            },


            setItemFilter(itemType) {
                if (this.itemTypeFilter == itemType)
                    this.itemTypeFilter = "";
                else
                    this.itemTypeFilter = itemType
            }

        },
        data () {
            return {
                nameFilter: "",
                itemTypeFilter: ""

            }
        },

    };
</script>

<style scoped>

    .sticky-toolbar {
        position: sticky;
        top: 0;
        background: #222;
        z-index: 1000;
        padding: 5px 15px;

    }



    .filter-icons {
        display: flex;
        width: 150px;
    }

    .filter-icons > div {
        border-radius: 3px;
        width: 100%;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active-item-filter {
        background-color: #ffa101;
    }

    .item {
        display: flex;
        padding: 10px;
        flex-direction: column;
        border-top: 1px solid #222;
    }

    .item > div:nth-child(1) {
        display: flex;
        flex: 1;
        flex-direction: row;
    }

    .item > div:nth-child(1) > div:nth-child(1){
        flex: 1;
    }

    .item > div:nth-child(1) > div:nth-child(2){
        width: 20px;
        display: flex;
        flex-direction: column;
    }

    .item > div:nth-child(1) > div:nth-child(2) > div:nth-child(1){
        color: #888;
        font-size: 0.7rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .item > div:nth-child(1) > div:nth-child(2) > div:nth-child(2){
        color: #52883f;
        font-size: 0.9rem;
        font-weight: bold;
        text-align: center;
    }


    .item > div:nth-child(2) {
        display: flex;
    }

    .item > div:nth-child(2) > div:nth-child(1) {
        _width: 70px;
        display: flex;
        flex-direction: column;
        _margin-right: 5px;
    }

    .item > div:nth-child(2) > div:nth-child(1) > *:first-child {
        font-size: 50px;
        color: #555;
        text-align: center;
    }


    .item > div:nth-child(2) > div:nth-child(2) {
        flex: 1;
        font-size: 0.75rem;
        font-weight: bold;
        _color: #3d7cd9;
        color: #6b9be2;

    }

    .item > div:nth-child(2) > div:nth-child(2) > div {
        display: block;

        margin-right: 5px;
        margin-top: 1px;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div > span:nth-child(1) {
        display: inline-block;
        width: 25px;
        text-align: center;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div:nth-child(odd)  {
        background-color: #2b2c2e;
    }
    .item > div:nth-child(2) > div:nth-child(2) > div:nth-child(even) {
        background-color: #212224;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div:first-child > span {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div:last-child > span {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .item > div:nth-child(2) > div:nth-child(3) {
        width: 20px;
        color: #333;
        display: flex;
        align-items: start;
    }

    .cubeText {
        color: #ff4720;
        display: inline-block;
        font-size: 0.8rem;
        font-weight: bold;
    }


    .runeBox {
        padding: 5px 0px;
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: start;
    }

    .runeBox > div {
        font-size: 0.7rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border-radius: 3px;
        background-color: #444;
        /*background: url("src/assets/rune.svg");*/
        margin: 1px;
    }

    .runeX:hover {
        background-color: #666 !important;
        cursor: pointer;
    }

    .runeX:active {
        background-color: #ffa101 !important;
    }


</style>