<template>
    <v-ons-page ref="pg">
        <div class="content">
            <div class="sticky-chest"  @click.prevent="consume()">

                <div>
                    <div v-for="r in availableRunes" :key="r.id" class="runeX" @click.prevent="addChestRune(r.name)">
                        <div>{{r.name}}</div>
                    </div>
                </div>
                <div>
                    <transition-group
                        enter-active-class="animate__animated animate__bounceIn animate__faster"
                        class="chestRunesSpace">
                        <click-rune
                            v-for="r in chestRuneList" :key="r.id"
                            :name="r.name"
                            :count="r.count"
                            @click="subChestRune(r.name)"
                        ></click-rune>
                    </transition-group>

                    <div class="emptyChestRunesSpace">
                        <span v-if="chestRuneList.length == 0"><v-ons-icon icon="fa-chevron-left"></v-ons-icon> {{$t('chest.help.addrunes')}}</span>
                        <span v-else><v-ons-icon icon="fa-chevron-up"></v-ons-icon> {{$t('chest.help.subrunes')}}</span>
                    </div>
                </div>

            </div>
            <div class="sticky-toolbar">
                <div @click="plusOne=!plusOne" :class="{'p1-checked': plusOne, 'p1': true}">
                    <span>+1</span>
                </div>

                <div style="flex: 1;"></div>
                <div>

                </div>


            </div>

            <rune-word-list
                :rune-word-list="filteredList"
                :kb="kb"
            ></rune-word-list>

            <div v-if="filteredList.length == 0" class="explain">
                {{$t('chest.help.general')}}
            </div>

            <div class="explain">
                {{$t('chest.help.plusone[0]')}}
                <strong class="explain-rune">{{$t('chest.help.plusone[1]')}}</strong> {{$t('chest.help.plusone[2]')}}
                <strong class="explain-rune-text">{{$t('chest.help.plusone[3]')}}</strong>{{$t('chest.help.plusone[4]')}}
            </div>

            <below-list></below-list>
        </div>
    </v-ons-page>
</template>

<script>

    import {
        isRune,
        runeWordList,
        runeList, runeOrderValue, kbFromVer
    } from '../util/itemknowledge'
        import RuneWordList from './RuneWordList'
        import ClickRune from './ClickRune'
        import Vue from 'vue';
        import i18n from "../i18n";
        import OptionsButton from "./OptionsButton";
        import BelowList from "./BelowList";
        import {decodePath} from "../util/navigation";
    import SingleRuneWord from "./SingleRuneWord";


    let lastChest = (function() {
        const lastChestRaw = localStorage.getItem("v1.props.chest") || "{}";
        try {
            lastChest = JSON.parse(lastChestRaw);
            return lastChest;
        }
        catch(err) {
            console.error("Unable to load chest:", err);
        }
        return {};
    })();

    export default {

        components: {SingleRuneWord, BelowList, OptionsButton, RuneWordList, ClickRune },

        computed: {

            allRunes() {
                let i = 1;

                return this.$store.state.items.runeList.map(r => {
                    return {
                        id: i++,
                        ...r,
                    }
                });

                // return runeList(this.kb).map(r => {
                //     return {
                //         id: i++,
                //         ...r,
                //     }
                // });
            },

            availableRunes() {
                const runes = this.allRunes;
                return [...runes];
            },

            chestRuneList() {
                const out = this.allRunes
                    .filter(r => this.chestRunes[r.name] > 0)
                    .map(r => {
                        const num = this.chestRunes[r.name];
                        return {
                            count: num,
                            ...r,
                        }
                    });
                return out;
            },

            kb() {
                const kb = this.$store.state.items.kb;
                return kb;
            },


            runeWordList() {
                const list = this.$store.state.items.runeWordList;
                return list;
            },

            filteredList() {
                const searchStr = this.nameFilter.toLowerCase();
                const kb = this.kb;
                const singleRunes = searchStr.split(' ').map(s => s.trim()).filter(s => s.length > 0);
                let searchRunesOnly = false;
                if (singleRunes.every(w => isRune(kb, w))) {
                    searchRunesOnly = true;
                }
                let out = [];
                if (this.plusOne) {
                    out = this.runeWordList
                        .map(rw => {return {...rw}})
                        .filter(rw => {
                            let missing = 0;
                            rw.craftInfo = [];
                            //rw.notMissing = [];
                            rw.cube.forEach(neededRune => {
                                const shortRune = neededRune.substring("rune:".length);
                                if (!(this.chestRunes[shortRune] > 0)) {
                                    missing++;
                                    // rw.missing.push(shortRune);
                                    rw.craftInfo.push({ index: rw.craftInfo.length, rune:shortRune, missing: true });
                                }
                                else {
                                    rw.craftInfo.push({ index: rw.craftInfo.length, rune:shortRune, missing: false });
                                }
                            });
                            return (missing == 1);
                        });
                }
                else {
                    out = this.runeWordList.filter(rw => {
                        const allRunes = rw.cube
                            .every(neededRune => this.chestRunes[neededRune.substring("rune:".length)] > 0)
                        return allRunes;

                    });
                }
                return out;
            },
        },
        watch: {
            chestRunesMod(v) {
                const storeVal = JSON.stringify(this.chestRunes);
                localStorage.setItem("v1.props.chest", storeVal)
            }
        },
        methods: {
            consume() {
                // nothing :-)
            },
            scrollUp() {
                //window.scrollTo(0,0);
                console.log(this.$refs.pg.$el.scrollTop);

                if (this.$refs.pg.$el.scrollTop == 0)
                    this.$refs.pg.$el.scrollTop = 310;
                else
                    this.$refs.pg.$el.scrollTop = 0;
            },
            addChestRune(runeName) {
                const num = this.chestRunes[runeName]||0;
                Vue.set(this.chestRunes, runeName, num + 1);
                this.chestRunesMod ++;
            },
            subChestRune(runeName) {
                const num = this.chestRunes[runeName]||0;
                if (num > 0)
                    Vue.set(this.chestRunes, runeName, num - 1);
                this.chestRunesMod ++;
            }
        },
        data () {
            return {
                nameFilter: "",
                itemTypeFilter: "",
                bigChest: false,
                plusOne: false,
                chestRunes: lastChest,
                chestRunesMod: 0,
            }
        },

    };
</script>

<style scoped>

    .p1 {
        margin-left: -5px;
        background-color: #333;
        width: 40px;
        font-family: 'Helvetica Neue', 'Helevetica', sans-serif;
        font-weight: bold;
        display: flex;
        align-content: center;
        justify-content: center;
        border-radius: 3px;
    }

    .p1-checked {
        background-color: #ffa101;
    }


    .explain {
        margin: 10px 10px 10px 10px;
        font-size: 0.7rem;
        font-weight: bold;
        padding: 7px 7px;
        color: #222;
        background-color: #555;
    }

    .sticky-chest {
        display: flex;
        /*position: sticky;*/
        top: 0;
        background: #222;
        z-index: 1000;
        padding: 0px 10px;
    }

    .sticky-chest > *:nth-child(1) {
        border-right: 1px solid #000;
    }

    .emptyChestRunesSpace {
        padding: 0px 10px;
        font-size: 0.8rem;
        color: #666;
        flex: 1;
    }

    .sticky-chest > *:nth-child(1),
    .chestRunesSpace {
        padding: 5px 0px;
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: start;
    }

    .chestRunesSpace {
        padding-left: 10px;
    }

    .sticky-chest > *:nth-child(1) > div {
        font-size: 0.7rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border-radius: 3px;
        background-color: #444;
        margin: 1px;
    }

    .chestRunesSpace > div {
        flex-direction: column;
    }



    .runeX:hover {
        background-color: #666 !important;
        cursor: pointer;
    }

    .runeX:active {
        background-color: #ffa101 !important;
    }


    .sticky-chest > *:nth-child(2) {
        flex: 1;
        overflow-y: auto;
    }

    .sticky-toolbar {
        display: flex;
        position: sticky;
        top: 0;
        background: #222;
        border-top: 1px solid #000;
        z-index: 1000;
        padding: 5px 15px;
    }

    .explain-rune {
        color: #eee;
        background-color: #444;
        padding: 1px 7px;
        border-radius: 2px;
    }

    .explain-rune-text {
        color: #eee
    }


</style>