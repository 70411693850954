import {decodePath} from "../../util/navigation";
import {
    isForHand,
    isForBody,
    isForShieldHand,
    isForHead,
    kbFromVer,
    runeOrderValue,
    runeWordList, runeList
} from "../../util/itemknowledge";
import i18n from "../../i18n";


export default {
    namespaced: true,
    state: () => ({
        lang: "",
        d2ver: "",
        runeWordList: [],
        runeList: [],
        kb: {},
    }),
    mutations: {

        modifyKB(state, {lang, d2ver}) {
            state.lang = lang;
            state.d2ver = d2ver;
            const kb = kbFromVer(d2ver).kb;
            const runeWords = runeWordList(kb);

            const newRuneWordList = runeWords.map(r => {

                let searchWord = r.name + " ";
                let runeSearch = "";
                const transName = i18n.t(`d2.${r.name}`);
                let linkName = i18n.t(`d2.${r.name}`, 'en');
                linkName = linkName.replaceAll(' ', '-');
                linkName = linkName.replaceAll("'", '');
                linkName = linkName.replaceAll("(", '');
                linkName = linkName.replaceAll(")", '');
                linkName = linkName.toLowerCase();

                searchWord += " " + transName;

                const effectsText = [];
                r.effects.forEach(effectInfo => {
                    const {name, value} = effectInfo;
                    let transName = name;
                    if (Array.isArray(value)) {
                        transName = i18n.t('d2.' + name, value);
                    }
                    else if (value) {
                        transName = i18n.t('d2.' + name, [value]);
                    }
                    else {
                        transName = i18n.t('d2.' + name);
                    }
                    searchWord += `${transName}`;
                    effectsText.push(`${transName}`)
                });

                const itemTexts = [];
                r.item.forEach(item => {
                    // let transName = item;
                    const transName = i18n.t('d2.' + item);
                    searchWord += `${transName}`;
                    itemTexts.push(`${transName}`)
                });

                let cubeText = "";
                let reqOrder = 0;
                (r.cube||[]).forEach(part => {

                    let partStr = part;
                    if (part.startsWith("rune:"))
                        partStr = part.substring("rune:".length);
                    reqOrder += runeOrderValue(kb, partStr);

                    cubeText += `${cubeText?" + ":""}${partStr}`
                    searchWord += `${cubeText?", ":""}${partStr}`;
                    runeSearch += `${runeSearch?", ":""}${partStr}`;
                })

                const link = `/${lang}/${d2ver}/runewords/${linkName}`;


                const outRune = {
                    ...r,
                    name: transName,
                    order: reqOrder,
                    linkName,
                    effectsText,
                    cubeText,
                    searchWord,
                    itemTexts,
                    runeSearch,
                    link,
                    isHand: isForHand(kb, r.item),
                    isArmor: isForBody(kb, r.item),
                    isHelm: isForHead(kb, r.item),
                    isShield: isForShieldHand(kb, r.item),
                };

                return outRune;
            }).sort((a, b) => a.order - b.order);

            // --------------------------------------------------------------

            const runes = runeList(kb);
            const newRuneList = runes.map(r => {

                let searchWord = r.name + " ";
                const linkName = r.name.toLowerCase();

                const sockingEffectText = {};
                Object.keys(r.sockingEffects).forEach(itemType => {
                    let outText = "";
                    r.sockingEffects[itemType].forEach(effectInfo => {
                        const {name, value} = effectInfo;

                        let transName = name;
                        if (Array.isArray(value)) {
                            transName = i18n.t('d2.' + name, value);
                        }
                        else if (value) {
                            transName = i18n.t('d2.' + name, [value]);
                        }
                        else {
                            transName = i18n.t('d2.' + name);
                        }

                        if (!sockingEffectText[itemType])
                            sockingEffectText[itemType] = [];
                        sockingEffectText[itemType].push(transName);

                        outText += `${outText ? ', ' : ''}${transName}`;
                    });

                    searchWord += " " + outText;
                });

                let cubeText = "";

                (r.cube||[]).forEach(part => {

                    let partStr = part;
                    if (part.startsWith("rune:"))
                        partStr = part.substring("rune:".length);
                    else if (part.startsWith("gem:")) {
                        partStr = i18n.t('d2.' + part);
                    }

                    cubeText += `${cubeText?" + ":""}${partStr}`
                })

                const link = `/${lang}/${d2ver}/runes/${linkName}`;

                const outRune = {
                    ...r,
                    link,
                    linkName,
                    sockingEffectText,
                    cubeText,
                    searchWord
                };

                return outRune;
            });

            state.runeWordList = newRuneWordList;
            state.runeList = newRuneList;
            state.kb = kb;
        },

    },
}