<template>
    <v-ons-page>

        <div class="tabs">
            <router-link to="/de/d2r/welcome" :class="{'active-tab':activeIndex!=1 && activeIndex!=2 && activeIndex!=3}">Welcome</router-link>
            <router-link to="/de/d2r/runes" :class="{'active-tab':activeIndex==1}">Runen</router-link>
            <router-link to="/de/d2r/runewords" :class="{'active-tab':activeIndex==2}">Runenwörter</router-link>
            <router-link to="/de/d2r/chest" :class="{'active-tab':activeIndex==3}">Kiste</router-link>
        </div>

        <div class="content">

            <welcome
                v-show="activeIndex!=1 && activeIndex!=2 && activeIndex!=3"
            ></welcome>

            <rune-tab
                v-show="activeIndex==1"
            ></rune-tab>

            <rune-words-tab
                v-show="activeIndex==2"
            ></rune-words-tab>

            <chest-tab
                v-show="activeIndex==3"
            ></chest-tab>



            <!--v-ons-tabbar
                _swipeable
                :position="tabPos"
                :tabs="tabs"
                :visible="true"
                :animation="tabAnim"
                :index.sync="activeIndex"
            ></v-ons-tabbar-->
        </div>

        <options-button
            @click="showPopover($event, 'up')"
        ></options-button>

        <v-ons-popover
            cancelable
            :visible.sync="popoverVisible"
            :target="popoverTarget"
            :direction="popoverDirection"
            :cover-target="coverTarget"
            class="props"
        >
            <div style="padding: 5px 10px">
                <v-ons-row>
                    <v-ons-col class="head">{{$t('props.headline')}}</v-ons-col>
                </v-ons-row>
                <v-ons-row>
                    <v-ons-col vertical-align="center">{{$t('props.lang')}}</v-ons-col>
                    <v-ons-col vertical-align="center">
                        <v-ons-segment style="width: 100%" :index.sync="langIndex">
                            <button>EN</button>
                            <button>DE</button>
                        </v-ons-segment>
                    </v-ons-col>
                </v-ons-row>
                <v-ons-row>
                    <v-ons-col vertical-align="center">{{$t('props.share')}}</v-ons-col>
                    <v-ons-col vertical-align="center">
                        <v-ons-button modifier="outline" @click="share" style="margin: 6px 0; padding: 0px; width: 100%; text-align: center">
                            <v-ons-icon icon="fa-share" ></v-ons-icon>
                        </v-ons-button>
                    </v-ons-col>
                </v-ons-row>
                <v-ons-row>
                    <v-ons-col style="margin-top: 10px; margin-bottom: 0px;">{{$t('props.links')}}</v-ons-col>
                    <v-ons-col vertical-align="center">
                        <v-ons-button modifier="quiet" style="margin: 0px 0; padding: 0px 2px">{{$t('props.imprint')}}</v-ons-button>
                    </v-ons-col>
                </v-ons-row>
                <v-ons-row>
                    <v-ons-col vertical-align="center"></v-ons-col>
                    <v-ons-col vertical-align="center">
                        <v-ons-button modifier="quiet" style="margin: 0px 0; padding: 0px 2px">{{$t('props.privacy')}}</v-ons-button>
                    </v-ons-col>
                </v-ons-row>
                <v-ons-row>
                    <v-ons-col vertical-align="center"></v-ons-col>
                    <v-ons-col vertical-align="center">
                        <v-ons-button modifier="quiet" style="margin: 0px 0; padding: 0px 2px">{{$t('props.sources')}}</v-ons-button>
                    </v-ons-col>
                </v-ons-row>

            </div>
        </v-ons-popover>

    </v-ons-page>
</template>

<script>


    import EmptyPage from './EmptyPage.vue';
    import Welcome from './Welcome.vue';
    import RuneTab from './RuneTab.vue';
    import RuneWordsTab from './RuneWordsTab.vue';
    import ChestTab from './ChestTab.vue';
    import i18n from './../i18n.js'
    import OptionsButton from "./OptionsButton";
    import {isOnDekstop} from "../util/browser";
    import {decodePath} from "../util/navigation";


    const mapIndexToTabNameKey = [
        "maintabs.runehilde",
        "maintabs.runes",
        "maintabs.runewords",
        "maintabs.chest"
    ]

    export default {
        components: {ChestTab, RuneTab, RuneWordsTab, OptionsButton, EmptyPage, Welcome },

        beforeCreate() {
            if (isOnDekstop(this.$ons)) {
                document.querySelector("html").classList.add("rh-desktop")
            }

            const path = this.$route.path;
            const pathInfo = decodePath(path);

            this.$store.commit('items/modifyKB', {
                lang: pathInfo.lang,
                d2ver: pathInfo.d2ver
            });

            console.log("===>", this.$store.state.items.runeWordList)
        },

        computed: {

            langIndex: {
                get() {
                    const path = this.$route.path;
                    const pathInfo = decodePath(path);
                    switch(pathInfo.lang) {
                        case 'en': return 0;
                        case 'de': return 1;
                    }
                    return 0;
                },
                set(v) {
                    let lang = 'en'
                    switch(v) {
                        case 0: lang = 'en'; break;
                        case 1: lang = 'de'; break;
                    }
                    //this.$store.commit('props/setLang', lang);

                    const path = this.$route.path;
                    const parts = path.split('/');
                    parts[1] = lang;
                    this.$router.push(parts.join("/"));
                    i18n.locale = lang;
                }
            },

            isLoading() {
                return false;
            },
            prefix() {
                return "";
            },
            title() {
                return this.tabs[this.activeIndex].label;
            },
            activeIndex: {
                get() {
                    return this.$store.state.navigator.protocolTabIndex;
                },
                set(newValue) {
                    this.$store.commit('navigator/changeTabIndex', newValue)
                }
            },
            isDesktopish() {
                return isOnDekstop(this.$ons);
            },
            tabPos() {
                if (this.isDesktopish)
                    return "top"
                return "bottom";
            },
            tabAnim() {
                return this.isDesktopish ? "none": "";
            },

            // currentLang() {
            //     return this.$store.state.props.lang;
            // },

            test1() {
                console.log("=>", this.$route.params.lang);
                return this.$route.params.lang;
            }

        },

        mounted() {



        },

        methods: {
            showPopover(event, direction, coverTarget = false) {
                this.popoverTarget = event;
                this.popoverDirection = direction;
                this.coverTarget = coverTarget;
                this.popoverVisible = true;
            },

            share() {
                if (navigator.share) {
                    navigator.share({
                        title: 'Runehilde',
                        text: i18n.t('props.share.mobile'),
                        url: 'https://runehilde.cschnack.de'
                    }).then(() => {

                    }).catch(() => {

                    })
                }
                else {
                    this.$ons.notification.toast(
                        i18n.t('props.share.dekstop'), {
                            timeout: 2000,
                        })
                        .then(()=>{});
                }
            }
        },

        watch: {



            langIndex(v, oldv) {
                let i=0;
                mapIndexToTabNameKey.forEach(langKey => this.tabs[i++].label = i18n.t(langKey));

                const path = this.$route.path;
                if (v != oldv) {

                    const pathInfo = decodePath(path);


                    this.$store.commit('items/modifyKB', {
                        lang: pathInfo.lang,
                        d2ver: pathInfo.d2ver
                    });
                }
            },


            $route(to, from) {
                if (this.onsenTabChange) {
                    this.onsenTabChange = false;
                    return;
                }
                const [,,,tab] = to.path.split('/');
                const [,,,fromTab] = from.path.split('/');
                if (tab == fromTab) {
                    return; // no tab change
                }
                console.log("Browser navigate to tab:", tab);
                let tabIndex = 0;
                switch(tab) {
                    default:
                    case 'home': tabIndex = 0; break;
                    case 'runes': tabIndex = 1; break;
                    case 'runewords': tabIndex = 2; break;
                    case 'chest': tabIndex = 3; break;
                }
                this.routerTabChange = true;
                this.activeIndex = tabIndex;
            },

            activeIndex(v) {
                let route = "";
                switch(v) {
                    default:
                    case 0: route = "home"; break;
                    case 1: route = "runes"; break;
                    case 2: route = "runewords"; break;
                    case 3: route = "chest"; break;
                }

                if (!this.routerTabChange) {
                    console.log("Tab navigate to:", route);
                    this.onsenTabChange = true;
                    const pathInfo = decodePath(this.$route.path);
                    let tabDetail = "";
                    console.log("--->", pathInfo);
                    // if (pathInfo.tabDetail)
                    //     tabDetail = `/${pathInfo.tabDetail}`;
                    this.$router.push(`/${pathInfo.lang}/${pathInfo.d2ver}/${route}${tabDetail}`)
                }
                this.routerTabChange = false;
            },
        },

        data () {

            let i = 0;

            return {

                popoverVisible: false,
                popoverTarget: null,
                popoverDirection: 'up',
                coverTarget: false,
                onsenTabChange: false,
                routerTabChange: false,

                // tabPos: "",



                tabs: [
                    {
                        icon: 'fa-home',
                        label: i18n.t(mapIndexToTabNameKey[i++]),
                        page: Welcome,
                        props: {
                            filter: 'current'
                        },
                        key: "welcome"
                    },
                    {
                        icon: 'fa-diamond',
                        label: i18n.t(mapIndexToTabNameKey[i++]),
                        props: {
                            filter: 'archive'
                        },
                        page: RuneTab,
                        key: "runes"
                    },
                    {
                        icon: 'fa-cubes',
                        label: i18n.t(mapIndexToTabNameKey[i++]),
                        props: {
                            filter: 'archive'
                        },
                        page: RuneWordsTab,
                        key: "words"
                    },
                    {
                        icon: 'fa-archive',
                        label: i18n.t(mapIndexToTabNameKey[i++]),
                        props: {
                            filter: 'archive'
                        },
                        page: ChestTab,
                        key: "chest"
                    },
                ]
            }
        },

    };
</script>

<style scoped>

    .props  {
        font-size: 0.8rem;
    }

    .page__content {
        margin-top: 50px;
    }

    .tabs {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background: #222;
        display: flex;
    }

    .tabs > * {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-decoration: none;
        font-size: 0.6rem;
    }

    .tabs > *.active-tab {
        color: #ffa101;
    }


    /*.tabs > *:hover {*/
    /*    background-color: #444;*/
    /*}*/

    .head {
        font-size: 0.65rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 7px;
        color: #aaa;
    }

</style>