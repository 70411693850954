import {decodePath} from "../../util/navigation";

const pathParts = decodePath(document.location.pathname||"");

export default {
    namespaced: true,
    state: () => ({
        stack: [],
        options: {},
        protocolTabIndex: pathParts.tabIndex,
        lastTouchedProtocol: "",
    }),
    mutations: {
        push(state, page) {
            state.stack.push(page);
        },
        pop(state) {
            if (state.stack.length > 1) {
                state.stack.pop();
            }
        },
        replace(state, page) {
            state.stack.pop();
            state.stack.push(page);
        },
        reset(state, page) {
            state.stack = [page || state.stack[0]];
        },
        options(state, newOptions = {}) {
            state.options = newOptions;
        },
        changeTabIndex(state, newIndex) {
            state.protocolTabIndex = newIndex;
        },
        touchedProtocol(state, protocolId) {
            state.lastTouchedProtocol = protocolId;
        }
    },
};