<template>
    <v-ons-list>
        <div class="item global-textselect" v-for="(i, rwi) in runeWordList" :key="i.name + '_' + rwi">
            <div class="global-textselect" @click="openLink(i.link)">
                <div>
                    <span class="itemHeader">
                        <!--div v-if="i.link"><a :href="i.link">{{i.name}}</a></div-->
                        <div v-if="i.link"><router-link :to="i.link">{{i.name}}</router-link></div>
                        <div v-else>{{i.name}}</div>
                        <div v-if="i.craftInfo && i.craftInfo.length > 0" class="cubeText">
                            <v-ons-icon icon="fa-cube"></v-ons-icon>&nbsp;<span v-for="(nm, index) in i.craftInfo" :key="index"><span v-if="index > 0"> + </span><span :class="{'missing-rune':nm.missing}">{{nm.rune}}</span></span>
                        </div>
                        <div v-else class="cubeText">
                            <v-ons-icon icon="fa-cube"></v-ons-icon> {{i.cubeText}}
                        </div>
                    </span>
                </div>
                <div>
                    <div>lvl</div>
                    <div>
                        {{i.reqLvl}}
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <div>
                        <v-ons-icon icon="fa-legal" v-if="isWeapon(i.item)"></v-ons-icon>
                        <v-ons-icon icon="fa-male" v-else-if="isArmor(i.item)"></v-ons-icon>
                        <v-ons-icon icon="fa-user" v-else-if="isHelm(i.item)"></v-ons-icon>
                        <v-ons-icon icon="fa-shield" v-else-if="isShild(i.item)"></v-ons-icon>
                        <v-ons-icon icon="fa-question" v-else></v-ons-icon>
                    </div>
                    <div>
                        <div v-for="(t, ti) in i.itemTexts" :key="i.name + ti">
                            <span>{{t}}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-for="(e, ei) in i.effectsText" :key="i.name + ei"><span>{{e}}</span></div>
                </div>
                <div>
                    <!--v-ons-icon icon="fa-star"></v-ons-icon-->
                </div>
            </div>

        </div>
    </v-ons-list>
</template>

<script>

    import {
        runeWordList,
        isRune,
        isForBody,
        isForHand,
        isForShieldHand,
        isForHead,
    } from '../util/itemknowledge'

    export default {

        components: {  },

        beforeCreate() {

        },

        computed: {

            // kb() {
            //     const ver = this.$store.state.props.d2version;
            //     const kb = availableKBs[ver].kb;
            //     return kb;
            // },

        },
        methods: {

            openLink(linkUrl) {
                //console.log("=>", linkUrl);
                if (!linkUrl)
                    return;

                this.$router.push(linkUrl);
            },

            isWeapon(itemList) {
                return isForHand(this.kb, itemList);
            },
            isArmor(itemList) {
                return isForBody(this.kb, itemList);
            },
            isHelm(itemList) {
                return isForHead(this.kb, itemList);
            },
            isShild(itemList) {
                return isForShieldHand(this.kb, itemList);
            },

            setItemFilter(itemType) {
                if (this.itemTypeFilter == itemType)
                    this.itemTypeFilter = "";
                else
                    this.itemTypeFilter = itemType
            }
        },
        data () {
            return {
                nameFilter: "",
                itemTypeFilter: "",
            }
        },

        props: [
            "runeWordList",
            "kb",
            "makeLinks"
        ]

    };
</script>

<style scoped>

    .item {
        display: flex;
        padding: 10px;
        flex-direction: column;
        border-top: 1px solid #222;
    }

    .item > div:nth-child(1) {
        display: flex;
        flex: 1;
        flex-direction: row;
    }

    .item > div:nth-child(1) > div:nth-child(1){
        flex: 1;
    }

    .item > div:nth-child(1) > div:nth-child(2){
        width: 20px;
        display: flex;
        flex-direction: column;
    }

    .item > div:nth-child(1) > div:nth-child(2) > div:nth-child(1){
        color: #888;
        font-size: 0.7rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .item > div:nth-child(1) > div:nth-child(2) > div:nth-child(2){
        color: #52883f;
        font-size: 0.9rem;
        font-weight: bold;
        text-align: center;
    }


    .item > div:nth-child(2) {
        display: flex;
    }

    .item > div:nth-child(2) > div:nth-child(1) {
        width: 70px;
        display: flex;
        flex-direction: column;
        margin-right: 5px;
    }

    .item > div:nth-child(2) > div:nth-child(1) > *:first-child {
        font-size: 50px;
        color: #555;
        text-align: center;
    }

    .item > div:nth-child(2) > div:nth-child(1) > *:nth-child(2) {
        font-size: 0.6rem;
        font-weight: bold;
        color: #d9d756;
        text-align: center;
    }

    .item > div:nth-child(2) > div:nth-child(1) > *:nth-child(2) > div > span {
        display: block;
        margin-top: 1px;
        padding: 0px 2px;
        background-color: #262510;
    }

    .item > div:nth-child(2) > div:nth-child(1) > *:nth-child(2) > div:nth-child(odd) > span {
        background-color: #1f1e0d;
    }


    .item > div:nth-child(2) > div:nth-child(2) {
        flex: 1;
        font-size: 0.75rem;
        font-weight: bold;
        _color: #3d7cd9;
        color: #6b9be2;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div > span {
        display: block;
        padding: 0px 5px;
        padding-right: 0px;
        margin-right: 5px;
        margin-top: 1px;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div:nth-child(odd) > span {
        background-color: #2b2c2e;
    }
    .item > div:nth-child(2) > div:nth-child(2) > div:nth-child(even) > span {
        _background-color: #424447;
        background-color: #212224;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div:first-child > span {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .item > div:nth-child(2) > div:nth-child(2) > div:last-child > span {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .item > div:nth-child(2) > div:nth-child(3) {
        width: 20px;
        color: #333;
        display: flex;
        align-items: start;
    }

    .missing-rune {
        color: white;
        background: #ffa101;
        padding: 0px 5px;
        border-radius: 3px;
    }

    .itemHeader > div:first-child {
        font-weight: bold;
    }

    .itemHeader > div:first-child > a {
        color: white;
        text-decoration: none;
    }

    .itemHeader > div:first-child > a:visited {
        color: white;
    }

    .cubeText {
        color: #ff4720;
        display: inline-block;
        font-size: 0.8rem;
        font-weight: bold;
    }


</style>