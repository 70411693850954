<template>
    <v-ons-page>
        <div class="content">
            <div class="sticky-toolbar" style="display: flex; align-items: center;">
                <div @click="nameFilter=''">
                    <v-ons-icon v-if="nameFilter" icon="fa-close" class="removeFilter" ></v-ons-icon>
                    <v-ons-icon v-else icon="fa-search"></v-ons-icon>
                </div>
                <v-ons-input v-model="nameFilter" :placeholder="$t('message.filter')" :style="filterInputStyle"></v-ons-input>
                <div class="filter-icons">
                    <div
                        @click="setItemFilter('weapon')"
                        :class="{'active-item-filter': itemTypeFilter == 'weapon'}"
                    >
                        <v-ons-icon icon="fa-legal"></v-ons-icon>
                    </div>
                    <div
                        @click="setItemFilter('armor')"
                        :class="{'active-item-filter': itemTypeFilter == 'armor'}"
                    >
                        <v-ons-icon icon="fa-male"> </v-ons-icon>
                    </div>
                    <div
                        @click="setItemFilter('helm')"
                        :class="{'active-item-filter': itemTypeFilter == 'helm'}"
                    >
                        <v-ons-icon icon="fa-user"></v-ons-icon>
                    </div>
                    <div
                        @click="setItemFilter('shield')"
                        :class="{'active-item-filter': itemTypeFilter == 'shield'}"
                    >
                        <v-ons-icon icon="fa-shield"></v-ons-icon>
                    </div>
                </div>
            </div>

            <rune-word-list
                :rune-word-list="filteredList"
                :kb="kb"
            ></rune-word-list>

            <!--div v-if="filteredList.length < runeWordList.length" class="clearFilterButton">
                <div @click="nameFilter=''; itemTypeFilter = ''">
                    <v-ons-icon icon="fa-close"></v-ons-icon>
                    Alle Runen Zeigen
                </div>
            </div-->

            <below-list></below-list>
        </div>
        <single-rune-word
            v-if="pickedWord"
            :runeword="pickedWord"
        ></single-rune-word>
    </v-ons-page>
</template>

<script>

import {
    runeWordList,
    isRune,
    isForBody,
    isForHand,
    isForShieldHand,
    isForHead,
    runeOrderValue, kbFromVer,
} from '../util/itemknowledge'
    import RuneWordList from './RuneWordList'
    import i18n from '../i18n'
    import OptionsButton from "./OptionsButton";
    import BelowList from "./BelowList";
    import {decodePath} from "../util/navigation";
import SingleRuneWord from "./SingleRuneWord";

    export default {

        components: {SingleRuneWord, BelowList, OptionsButton, RuneWordList },

        beforeCreate() {

        },

        computed: {

            filterInputStyle() {
                return {
                    marginLeft: "10px",
                    flex: 1
                }
            },

            pickedWord() {
                const pathParts = decodePath(this.$route.path);
                return pathParts.tabDetail;
            },

            kb() {
                const kb = this.$store.state.items.kb;
                return kb;
            },


            runeWordList() {
                const list = this.$store.state.items.runeWordList;
                return list;
            },

            filteredList() {
                if (!this.nameFilter && !this.itemTypeFilter)
                    return this.runeWordList;

                const searchStr = this.nameFilter.toLowerCase();
                // const ver = this.$store.state.props.d2version;
                const kb = this.kb;
                const singleRunes = searchStr.split(' ').map(s => s.trim()).filter(s => s.length > 0);
                let searchRunesOnly = false;
                if (singleRunes.every(w => isRune(kb, w))) {
                    searchRunesOnly = true;
                }
                let out = this.runeWordList;
                if (this.nameFilter) {
                    out = searchRunesOnly
                        ? out.filter(r => {
                            for (let i=0; i<singleRunes.length; i++) {
                                // OR
                                if (r.runeSearch.toLowerCase().indexOf(singleRunes[i]) > -1)
                                    return true;
                            }
                            return false;
                        })
                        : out.filter(r => r.searchWord.toLowerCase().indexOf(searchStr) > -1)
                }

                if (this.itemTypeFilter) {
                    out = out.filter(r => {
                        if (!r.item)
                            debugger;
                        switch(this.itemTypeFilter) {
                            case "weapon": return isForHand(this.kb, r.item) ? true : false;
                            case "armor": return isForBody(this.kb, r.item) ? true : false;
                            case "helm": return isForHead(this.kb, r.item) ? true : false;
                            case "shield": return isForShieldHand(this.kb, r.item) ? true : false;
                        }
                        return false;
                    })
                }
                return out;
            },



        },
        methods: {
            setItemFilter(itemType) {
                if (this.itemTypeFilter == itemType)
                    this.itemTypeFilter = "";
                else
                    this.itemTypeFilter = itemType
            }
        },
        data () {
            return {
                nameFilter: "",
                itemTypeFilter: ""
            }
        },

    };
</script>

<style scoped>


    .sticky-toolbar {
        position: sticky;
        top: 0;
        background: #222;
        z-index: 1000;
        padding: 5px 15px;

    }

    .rune-details {
        font-size: 0.5rem;
        font-weight: bold;
        color: #3d7cd9;
    }

    .filter-icons {
        display: flex;
        width: 150px;
    }

    .filter-icons > div {
        border-radius: 3px;
        width: 100%;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active-item-filter {
        background-color: #ffa101;
    }

    .removeFilter {
        background: #ffa101;
        padding: 5px;
        color: white;
        border-radius: 3px;
    }

    .clearFilterButton {
        padding: 10px;
        display: flex;
        justify-content: center;
    }

    .clearFilterButton > div {
        background: #ffa101;
        padding: 5px;
        color: white;
        font-size: 0.7rem;
        font-weight: bold;
        border-radius: 3px;
    }

    .cubeText {
        color: #ff4720;
        display: inline-block;
        font-size: 0.5rem;
        font-weight: bold;

    }

</style>