import { createLogger } from 'vuex'

import navigator from './modules/navigator.js'
// import props from './modules/props.js'
import items from './modules/items.js'



const plugins = [];

if (process.env.NODE_ENV !== 'production') {
    //plugins.push(createLogger());
}

const strict = false; //process.env.NODE_ENV !== 'production';



export default {
    strict,
    plugins,
    modules: {
        navigator,
        // props,
        items,
    }
};