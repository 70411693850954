import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {getMainNavigatorLanguage} from './util/lang'
import {decodePath} from "./util/navigation";

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json5$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}


const path = location.pathname;
const pathInfo = decodePath(path);

//const navigatorLanguage = getMainNavigatorLanguage();
//const lastLanguage = localStorage.getItem("v1.props.language");
// const tryLanguage = lastLanguage || navigatorLanguage || 'de';

console.log(`
----------
✨ Lang: ${pathInfo.lang}
----------
`);

export default new VueI18n({
  locale: pathInfo.lang,
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
