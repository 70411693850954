<template>
    <div class="main">
        <div class="head">
            <h1>{{runeInfo.name}}</h1>
            <div><v-ons-button @click="close()"><v-ons-icon icon="fa-close"></v-ons-icon></v-ons-button></div>
        </div>

    </div>
</template>

<script>

import {
    runeWordList,
    isRune,
    isForBody,
    isForHand,
    isForShieldHand,
    isForHead, kbFromVer, runeList, runeOrderValue,
} from '../util/itemknowledge'
import {decodePath} from "../util/navigation";
import i18n from "../i18n";
import {toCmpString} from "../util/strings";

export default {
    name: "SingleRune",

    methods: {

        close() {
            // const pathParts = decodePath(this.$route.path);
            // const path = `/${pathParts.lang}/${pathParts.d2ver}/runewords`;
            // this.$router.push(path);

            this.$router.back();

        },

    },

    computed: {

        runeInfo() {
            const searchCmp = this.rune; // toCmpString(this.runeword);
            let candiates = [];
            let similar = [];
            const runelists = this.$store.state.items.runeList;
            runelists.forEach(r => {
                if (r.linkName == searchCmp) {
                    candiates.push(r);
                }
            });
            return candiates.length > 0 ? candiates[0] : {}
        },




    },

    props: [
        "rune"
    ]
}
</script>

<style scoped>

.main {
    background: #000;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 1000;
    padding: 10px 20px;
}

.head {
    display: flex;
}

.head > *:first-child {
    padding: 0;
    margin: 0;
    flex: 1;
    padding-left: 30px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.head > div:nth-child(2) {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.head > div:nth-child(2) > .button {
    background-color: #999;
    color: #b12525;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.head > div:nth-child(2) > .button > * {
    margin: 0;
    padding: 0;
}

</style>