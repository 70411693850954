<template>
    <v-ons-page>
        <div class="content">

            bin leer
        </div>


    </v-ons-page>
</template>

<script>



    export default {

        components: {  },

        beforeCreate() {

        },

        computed: {

        },
        methods: {

        },
        data () {
            return {

            }
        },

    };
</script>

<style>

</style>