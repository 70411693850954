<template>
    <div></div>
</template>

<script>
export default {
    name: "BelowList"
}
</script>

<style scoped>

    div {
        height: 70px;
    }
</style>