<template>
    <transition
        @mousedown.prevent="$emit('click')"
        >
        <div class="rune global-rune" :key="count" @mousedown.prevent="$emit('click')">
            <div>{{name}}</div>
            <div>{{count}}</div>
        </div>
    </transition>
</template>
<script>
    //import Vue from 'vue';

    export default {

        components: { },

        props: [
            "name",
            "count",
        ],
    };
</script>
<style scoped>

    .rune {
        font-size: 0.7rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border-radius: 3px;
        background-color: #444;
        margin: 1px;
        cursor: pointer;
    }



    .rune > div:nth-child(2){
        color: #ffa101;
    }


</style>